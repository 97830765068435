import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import CardWrap, { Card } from '../components/designer-cards'

export default () => (
  <StaticQuery
    query={graphql`
      query WatchDesignersQuery {
        allDesignersJson(filter: {categories: {regex: "/Watch/"}}, sort: {fields: display_order, order: DESC})  {
          edges {
            node {
              category_image
              description
              display_order
              name
            }
          }
        }
      }
      `}
      render={ data => (
        <CardWrap title="Watch Designers">
          {data.allDesignersJson.edges.map((designer, index) => {
            let designerName = ''

            switch (designer.node.name) {
              case 'Rolex':
                designerName = 'rolex'
                break
              case 'Michele':
                designerName = 'michele'
                break
              case 'Tudor':
                designerName = 'tudor'
                break
              case 'Seiko':
                designerName = 'seiko'
                break
              default:
                designerName = designer.node.name
                break
            }

            return  <Card key={index} designer={designer} bgColor='white' designerName={designerName}/>
          })}
        </CardWrap>
      )}
  />
)
