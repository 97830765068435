import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
import { md, GreenBtn } from '../common'

export default () => (
  <Wrap>
    <Copy>
      <h1>TUDOR WATCHES</h1>
      <br />
      <p>Tudor watches have a long history for quality and impeccable design at a great price. At Underwood's, we are proud to have been one of the first official Tudor retailers when the brand was reintroduced in the United States in 2013. If you're looking for a luxury watch with a vintage or throwback style, Tudor is a great way to go. Other designs suit discerning tastes.</p>
      <p>Check out Underwood's current inventory at our stores in Ponte Vedra and San Marco.</p>
      <Link to="/designer/tudor"><GreenBtn>Explore The Collection</GreenBtn></Link>
    </Copy>
  </Wrap>
)

const Wrap = styled.div`
  background: url("https://s3.amazonaws.com/underwoods/Watches/tudor-watch-CTA.jpg");
  background-size: cover;
  background-position: center top;
  padding: 4rem;
  color: white;
  text-align: center;

  ${md} {
    padding: 1rem;
  }
`

const Copy = styled.div`
  width:40%;
  margin-right:auto;
  padding: 1rem;
  line-height:24px;

  h1 {
    line-height:32px;
  }

  ${md} {
    padding:0;
    margin:0;
    width:100%;
  }
`
