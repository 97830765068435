import React, { useState } from 'react'
import { navigate } from '@reach/router' 
import Layout from '../components/layout'
import SEO from '../components/seo'
import Contact from '../components/contactForm'
import JoinUs from '../sections/joinUs'
import GreenBar from '../sections/watch-greenBar4'
import Video from '../sections/rolexCareVideo'
import Tudor from '../sections/wa-tudor'
import Designers from '../sections/watch-designers'
import { PageHead, GreenBtn } from '../common'
import Modal from '../components/modal'
import { WatchCards } from './watch-repair'

export default () => {
  const [show, setShow ] = useState(false)

  function handleClick() {
    setShow(!show)
    if (typeof window !== "undefined") {
      window.scrollTo(0,0)
      if (show) {
        navigate(`${window.location.pathname}${window.location.hash}`)
      }
    }
  }
  return(
  <Layout headImg={`https://s3.amazonaws.com/underwoods/header_watches.jpg`}>
    <SEO
      title="Watches"
      description="Underwood’s carries a wide selection of watches including Rolex, Tudor, Raymond Weil, Seiko, Michele, Tavannes and David Yurman. Stop in any of our stores to try a fine timepiece on your wrist!" />
    <PageHead>
      <h1>WATCHES</h1>
      <p>Underwood’s carries one of the largest selection of quartz and automatic watches in Jacksonville and Ponte Vedra Beach, FL. We feature Rolex, Seiko, Michele, Tavannes, Raymond Weil and Tudor in our inventories. A watch is a great way to commemorate a special occasion and can be cherished forever. Underwood’s offers a five-year warranty on the movement of all of the watches we carry.</p>
    </PageHead>
    <Designers />
    <Tudor />
    <div>
      <PageHead>
        <h1>WATCH REPAIR</h1>
        <p>Underwood’s is fortunate to have a watch repair shop designed and outfitted by Rolex Watch Co. Underwood’s watch technicians are certified as either CW21 or SAWTA, the two highest level designations in the world, denoting a high level of expertise in their craft. Our watch shop specializes in the servicing and repair of Rolex watches using only authorized repair parts and tools for every job. Our watchmakers have received extensive training on all caliber movements offered by Rolex and perfect their trade with continuing education on an annual basis. Both of our watchmakers are extremely skilled and will handle your watch with the utmost care.</p>
        <p>Just like a fine car, a Rolex watch can last a lifetime if it is serviced with the correct equipment and replacement parts on a regular basis. If not serviced, it can prematurely wear to the point that it can no longer be repaired. Our technicians will remove the movement of your watch, clean each individual part with the proper oils, replace any worn parts, reassemble your watch while adjusting the timekeeping and then refinish the case and each link with a polish that will make your watch look close to brand new. After servicing, your Rolex will have a 2-year warranty on the inside movement. Prices for an overhaul start at $750 and depend on the model of your watch. If you purchased your Rolex from us all future services will be 10% off. The average time frame an overhaul takes to be completed is 10-15 business days*. You can take your Rolex to any of our locations to be serviced.</p>
        <p css={`font-size:12px;`}>*Subject to customer approval and availability of parts.</p>
        <GreenBtn onClick={handleClick}>Book An appointment</GreenBtn>
      </PageHead>
    </div>
    <GreenBar />
    <WatchCards />
    <Video />
    <JoinUs />
    <Modal show={ show } toggle={ handleClick }>
      <Contact />
    </Modal>
  </Layout>
)}
